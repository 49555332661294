import { createApp, h } from "vue";
import App from "./App.vue";
import BootstrapVue from "bootstrap-vue-3";
import "bootstrap/dist/css/bootstrap.css";
import "./scss/app.scss";
import { setupRouter } from "./router";
import { setupI18n } from "./i18n";
import VueGtag from "vue-gtag";

// MarqueeText
import MarqueeText from "vue-marquee-text-component";
import mitt, { Emitter } from "mitt"; // Import mitt
const emitter = mitt(); // Initialize mitt

// Custom Scrollbar
import CustomScrollbar from "custom-vue-scrollbar";
import "custom-vue-scrollbar/dist/style.css";
import "./scss/scrollbar.scss";

// Apex charts
import VueApexCharts from "vue3-apexcharts";

import UAParser from "ua-parser-js";
const uaParser = new UAParser();
const i18n = setupI18n();

const Router = setupRouter(i18n);

const app = createApp({
  render: () => h(App),
});

app.config.globalProperties.$emitter = mitt();
app.config.globalProperties.$isMobile = uaParser.getDevice().type == 'mobile'
// Google analytics

app.use(i18n);
app.use(Router);
app.use(VueApexCharts);
app.use(BootstrapVue);

app.use(VueGtag, {
  config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID }
}, Router)

app.component(MarqueeText.name, MarqueeText);
app.component(CustomScrollbar.name, CustomScrollbar);
app.component("ApexCharts", VueApexCharts);

app.provide("emitter", emitter);
app.provide("isMobile", uaParser.getDevice().type == "mobile");

app.mount("#app");

// Apollo client
import { provideApolloClient } from '@vue/apollo-composable'
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context';

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = process.env.VUE_APP_STRAPI_TOKEN
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: process.env.VUE_APP_API_URL
})

provideApolloClient(
  new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  })
);

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    emitter: Emitter<any>; // replace it with the right type
  }
}
